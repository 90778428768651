import 'material-design-icons-iconfont/dist/material-design-icons.css'

<template>
  
  <div class="pa-3" id="tester">

    <uploadingDialog v-bind:uploadingDialog = "this.uploadingDialog"></uploadingDialog>
    <mySnackBar      v-bind:MySnackBar      = "this.MySnackBar"></mySnackBar>

    <v-container>
    <v-row class="pt-2 pa-1 mb-2 d-flex justify-space-between align-baseline">
           
       <div>Gas Installation Form</div>
         
        <div>   
        <v-btn  v-if = "this.$router.currentRoute.path != '/'"  @click="formReviewUpload" class="mx-2" fab dark small color="red darken-3">
           <v-icon dark>mdi-file-pdf</v-icon>
        </v-btn>
      
        <v-btn  v-if = "this.$router.currentRoute.path != '/'" @click="formReview" class="mx-2" fab medium color="success">
          <v-icon dark>mdi-presentation-play</v-icon>
        </v-btn>
        
        </div>

    </v-row>
    </v-container>

    <v-row class="mt-0" justify="center">
     <v-expansion-panels class="expansion_panels">

      <!-- Instructions 
        <v-expansion-panel class="expansion_panel mb-1"> 
        <v-expansion-panel-header>Gas Installation/Call Out Instructions</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="header text-justify">
            Gas Installation/call out instuctions
         </p>
        </v-expansion-panel-content>
        </v-expansion-panel>
      end Instructions -->
    
      <!-- customerComponent -->
       <customer v-bind:customer = "customer" />
      <!-- customerComponent -->

      <!-- Job Details -->
        <v-expansion-panel class="expansion_pane mb-1"> 
         <v-expansion-panel-header>Job Details</v-expansion-panel-header>
         <v-expansion-panel-content>
            
            <v-container>

             <v-row dense>

                 <v-col dense cols="12" sm="6">
                    <v-text-field  outlined dense v-model= "jobDetails.contractor"     label="Name of Contractor"> </v-text-field>
                 </v-col>

                 <v-col dense cols="12" sm="6">
                    <v-text-field  outlined dense v-model= "jobDetails.engineer"       label="Name of Engineer"> </v-text-field>
                 </v-col>
         
                 <v-col cols="12" sm="6">
                     <v-textarea  clearable dense outlined v-model= "jobDetails.typeOfInstallation"  label="Type of Installation"></v-textarea>
                 </v-col>
       
                   <v-col cols="12" sm="6">
                    <v-textarea  clearable dense outlined v-model= "jobDetails.detailOfWork"        label="Detail of Work"></v-textarea>
                  </v-col>

                 <v-col cols="12" sm="6">
    
                                       <TimeSelector 
                                        caption        = "Arrival Time" 
                                        v-bind:enabled = "true"
                                        v-bind:curTime = "jobDetails.arrivalTime"
                                        @updated="(e) => {jobDetails.arrivalTime = e}"
                                     ></TimeSelector>
                </v-col>

                 <v-col cols="12" sm="6">
                                       <TimeSelector 
                                      caption        = "Departure Time" 
                                       v-bind:enabled = "true"
                                       v-bind:curTime = "jobDetails.departureTime"
                                       @updated="(e) => {jobDetails.departureTime = e}"
                                     ></TimeSelector>
                </v-col>

                  <v-col cols="12" sm="6">
                                       <DateSelector 
                                       caption        = "Job Date" 
                                       v-bind:enabled = "true"
                                       v-bind:curDate = "jobDetails.dateOfVisit"
                                       @updated="(e) => {jobDetails.dateOfVisit = e}"
                                     ></DateSelector>
                  </v-col>



                <v-col class="d-flex justify-space-between" cols="12">
                      <div class="mr-2 mb-2">Is work completed ?</div>
                      <div align="right" ><toggle-button
                              :value = "jobDetails.workCompleted"
                              :labels = "{checked: 'Yes', unchecked: 'No'}"
                              :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                              color  = "#82C7EB"
                              :width = "60"
                              :height = "28"
                              v-model= "jobDetails.workCompleted"
                              @change= "(v) => jobDetails.workCompeted"
                      /></div>
              </v-col>
              <v-text-field  v-if="!jobDetails.workCompleted" dense outlined v-model= "jobDetails.workCompletedComment"  label="Who will Arrange Further Works"></v-text-field>
         
            </v-row>
 
          </v-container>
 
        </v-expansion-panel-content>
        </v-expansion-panel>
      <!-- end Job Details -->
              
      <!-- soundnessTest -->
        <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Soundness Test</v-expansion-panel-header>
        <v-expansion-panel-content id="adjustme">

            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">Pressure Test</th>
                  <th class="text-left">Leak Detection Fluid</th>
                  <th class="text-left">Installation Sound</th>
                </tr>
              </thead>
              <tbody>

                <tr v-for="(item, index) in soundnessTest" :key="index" >
                   <td class="smallFont pa-0 pl-1" style="width:150px">{{ item.label }}</td>
                  
                   <td style="width:115px" class="pr-0">
                    <toggle-button
                                :value        = "item.pressureTest"
                                :labels       = "{checked: 'Yes', unchecked: 'No'}"
                                :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                                color         = "#82C7EB"
                                :width        = "60"
                                :height       = "28"
                                v-model       = "item.pressureTest"
                                @change       = "(v) => item.pressureTest"
                    />
                   </td>

                   <td style="width:115px" class="pr-0">
                      <toggle-button
                                :value        = "item.leakDetectionFluid"
                                :labels       = "{checked: 'Yes', unchecked: 'No'}"
                                :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                                color         = "#82C7EB"
                                :width        = "60"
                                :height         = "28"
                                v-model = "item.leakDetectionFluid"
                                @change = "(v) => item.leakDetectionFluid"
                      />
                   </td>
              
                   <td style="width:115px" class="pr-0">
                       <toggle-button
                                :value  = "item.installationSound"
                                :labels = "{checked: 'Yes', unchecked: 'No'}"
                                :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                                color  = "#82C7EB"
                                :width = "60"
                                :height = "28"
                                v-model = "item.installationSound"
                                @change = "(v) => item.installationSound"
                    />

                   </td>
                </tr>
              </tbody>
            </v-simple-table>
        
           <v-container>

              <v-col class="d-flex">
                      <div class="mr-2 mb-2">Internal Installation Inspected</div>
                      <div><toggle-button
                              :value = "jobDetails.internalInstallationInspected"
                              :labels = "{checked: 'Yes', unchecked: 'No'}"
                              :switch-color = "{checked: '#25EF02', unchecked: 'red'}"
                              color  = "#82C7EB"
                              :width = "60"
                              :height = "28"
                              v-model= "jobDetails.internalInstallationInspected"
                              @change= "(v) => jobDetails.internalInstallationInspected"
                      /></div>
              </v-col>

              <v-col v-if = "jobDetails.internalInstallationInspected" class="d-flex">
                      <div class="mr-2 mb-2">if Yes, Are appliances operating satisfactory.?</div>
                      <div><toggle-button
                              :value          = "jobDetails.internalInstallationSatisfactory"
                              :labels         = "{checked: 'Yes', unchecked: 'No'}"
                              :switch-color   = "{checked: '#25EF02', unchecked: 'red'}"
                              color           = "#82C7EB"
                              :width          = "60"
                              :height         = "28"
                              v-model         = "jobDetails.internalInstallationSatisfactory"
                              @change         = "(v) => jobDetails.internalInstallationSatisfactory"
                      /></div>
              </v-col>
      
           </v-container>
       
        </v-expansion-panel-content>
        </v-expansion-panel>
      <!-- end soundnessTest -->

      <!-- Fitted Details-->
        <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Fitted Details</v-expansion-panel-header>
          <v-expansion-panel-content>
            <hr>

        <v-container>

              <v-row dense>
        
               <v-col dense cols="12" sm="4">
                      <v-card dense elevation="1" tile >
                         <v-card-text dense>

                         <v-radio-group class="myLabels" dense label = "EMERGENCY CONTROL VALVE (fitted): " v-model  = "fitted.ecv">
                            <v-radio label="Yes" value="YES"></v-radio>
                            <v-radio label="No"  value="NO"></v-radio>
                        </v-radio-group>

                        <v-radio-group style="margin-top: 0px" dense v-model  = "fitted.ecvCorrectlyLabelled" label = "Correctly Labelled">
                           <v-radio label="Yes"  value="YES"></v-radio>
                           <v-radio label="No"   value="NO"></v-radio>
                        </v-radio-group>

                        </v-card-text>
                      </v-card>
               </v-col>
        
               <v-col  cols="12" sm="4">
                      <v-card elevation="1" tile >
                         <v-card-text >
                            <v-radio-group class="myLabels" dense label = "EQUIPOTENTIAL BONDING (fitted) " v-model  = "fitted.equipotentialBonding">
                              <v-radio label="Yes" value="YES"></v-radio>
                              <v-radio label="No"  value="NO"></v-radio>
                            </v-radio-group>
         
                        <v-radio-group  style="margin-top: 0px" dense v-model  = "fitted.equipotentialBondingAdvised" label = "if no, customer advised">
                           <v-radio label="Yes"  value="YES"></v-radio>
                           <v-radio label="No"   value="NO"></v-radio>
                        </v-radio-group>

         
                        </v-card-text>
                      </v-card>
               </v-col>
        
               <v-col  cols="12" sm="4">
                      <v-card elevation="1" tile >
                         <v-card-text >
                               <v-radio-group class="myLabels" dense label = "UPSO/OPSO (fitted) " v-model  = "fitted.upso">
                                  <v-radio label="Yes" value="YES"></v-radio>
                                  <v-radio label="No"  value="NO"></v-radio>
                               </v-radio-group>
                               <v-text-field  dense outlined v-model="fitted.upsoDetails" label="UPSO Details"></v-text-field>
                          </v-card-text>
                      </v-card>
               </v-col>
              </v-row>
          
        </v-container>
  
          </v-expansion-panel-content>
        </v-expansion-panel>
      <!-- Fitted Section-->

      <!-- vessels-->
        <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Vessel(s)</v-expansion-panel-header>
        <v-expansion-panel-content>

          <hr>
          <v-container>
              
              <v-row dense>
                  <v-col cols="12" sm="3">
                    <v-text-field  class ="child" dense outlined v-model="tankDetails.no"      label="No"> </v-text-field>
                  </v-col>
    
                  <v-col cols="12" sm="3">
                      <v-text-field  class ="child" dense outlined v-model="tankDetails.size"    label="Size"> </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">

                                      <DateSelector 
                                        caption        = "Next Test Date" 
                                        v-bind:enabled = "true"
                                        v-bind:curDate = "tankDetails.nextTestDate"
                                        @updated="(e) => {tankDetails.nextTestDate = e}"
                                     ></DateSelector>

                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field  class ="child" dense outlined v-model="tankDetails.gasContents"         label="Gas Contents"></v-text-field>
                  </v-col>

                  <v-col dense cols="12">
                        <v-text-field  class ="child" dense outlined v-model="tankDetails.serialNumbers"       label="Serial Numbers"></v-text-field>
                  </v-col>
           

              </v-row>
             </v-container>
               
            
              <hr>

               
              <v-container>
                <v-row >

                    <v-col cols="12" sm="4" >  
                       <v-radio-group  label="Colour"  v-model="tankDetails.colour">
                             <v-radio  label="Underground"       value="U"></v-radio>
                             <v-radio  label="Green"             value="G"></v-radio>
                             <v-radio  label="Pale Green"        value="PG"></v-radio>
                             <v-radio  label="White"             value="W"></v-radio>
                       </v-radio-group>
                    </v-col>

                    <v-col cols="12" sm="4">
                         <v-radio-group label="Next Test Due" v-model="tankDetails.nextTestDue">
                            <v-radio label="X"    value="X"></v-radio>
                            <v-radio label="XX"   value="XX"></v-radio>
                          </v-radio-group>
                    </v-col>

                      <v-col dense cols="12" sm="4">
                          <v-radio-group label="Tank Type" v-model="tankDetails.gasSupply">
                            <v-radio label="Cylinder"    value="cylinder"></v-radio>
                            <v-radio label="Bulk Supply" value="bulkSupply"></v-radio>
                          </v-radio-group>
                      </v-col>

              </v-row>
            </v-container>  
  
      
        </v-expansion-panel-content>
        </v-expansion-panel>
      <!-- end vessels-->

       <!-- Section 3 - Escape Details-->
         <v-expansion-panel class="expansion_pane mb-1"> 
        <v-expansion-panel-header>Gas Escape Details</v-expansion-panel-header>
        <v-expansion-panel-content>
        <hr>

        <v-container>

              <p><strong>Escape Details:</strong></p>
              <hr>
        
              <v-row class="mt-1" dense>
        
               <v-col  cols="12" sm="6">
                      <v-radio-group dense label = "Where did customer smell gas ?" v-model  = "gasEscape.escapeSmellLocation">
                         <v-radio label="Internally" value="internally"></v-radio>
                         <v-radio label="Externally" value="externally"></v-radio>
                      </v-radio-group>
               </v-col>
        
               <v-col cols="12" sm="6">
                      <v-radio-group dense label = "Was Gas Supply isloated on arrival.?" v-model  = "gasEscape.gasSupplyIsolated">
                         <v-radio label="Yes" value="YES"></v-radio>
                         <v-radio label="No"  value="NO"></v-radio>
                      </v-radio-group>
               </v-col>
        
               <v-col  cols="12" sm="6">
                      <v-radio-group dense label = "Details of Gas Escape.?"  v-model  = "gasEscape.escapeDetail">
                         <v-radio label="Low Pressure Vapour"           value="Low Pressure Vapour"></v-radio>
                         <v-radio label="Intermediate Pressure Vapour"  value="Intermediate Pressure Vapour"></v-radio>
                         <v-radio label="High Pressure Vapour"          value="High Pressure Vapour"></v-radio>
                         <v-radio label="Liquid"                        value="Liquid"></v-radio>
                      </v-radio-group>
               </v-col>
              
              </v-row>
        
              <p><strong>Findings:</strong></p>
              <hr>
              <v-row class="mt-1">
        
              <!-- gas Supply Safe For use -->
              <v-col cols="12" sm="6">
                        <v-card
                          elevation="2"
                           tile
                        >
                 
                        <v-card-text>
                           <v-radio-group dense label = "Is gas supply safe for use ?" v-model  = "gasEscape.isGasSupplySafeForUse">
                            <v-radio label="Yes"  value="YES"></v-radio>
                            <v-radio label="No"   value="NO"></v-radio>
                          </v-radio-group>
                        </v-card-text>
                        </v-card>  
              
              </v-col>
              <!-- gas Supply Safe For use -->
          
              <!-- Riddor Reportable -->
              <v-col cols="12" sm="6">

                      <v-card
                           elevation="2"
                           tile
                      >
                        <v-card-text>
                            <v-radio-group dense label = "Is installation RIDDOR reportable ?" v-model  = "gasEscape.isInstallationRiddorReportable">
                              <v-radio label="Yes"  value="YES"></v-radio>
                              <v-radio label="No"   value="NO"></v-radio>
                            </v-radio-group>
                        </v-card-text>
                      </v-card>
              </v-col>
              <!-- Riddor Reportable -->
             
              <!-- Installation Safe For use -->
              <v-col cols="12" sm="6">
                  <v-card
                          elevation="2"
                           tile
                    >
                      <v-card-text>
                      <v-radio-group dense label = "Is Installation safe for use ?" v-model  = "gasEscape.isInstallationSafeForUse">
                         <v-radio label="Yes"  value="YES"></v-radio>
                         <v-radio label="No"   value="NO"></v-radio>
                      </v-radio-group>
     
                      <v-radio-group style="margin-top: 0px" dense v-model  = "gasEscape.isInstallationSafeForUseNoticeRaised" label = "If No, has a Warning notice been raised ?" row>
                          <v-radio label="Yes"  value="YES"></v-radio>
                          <v-radio label="No"   value="NO"></v-radio>
                      </v-radio-group>

                      </v-card-text>
                  </v-card> 
                      
              </v-col>
              <!-- Installation Safe For use -->

               <!-- Warning Labels been affixed -->
              <v-col cols="12" sm="6">
                  <v-card
                          elevation="2"
                           tile
                    >
                      <v-card-text>
                      <v-radio-group dense label = "Have warning Labels been affixed ?" v-model  = "gasEscape.haveWarningLabelsBeenAffixed">
                         <v-radio label="Yes"  value="YES"></v-radio>
                         <v-radio label="No"   value="NO"></v-radio>
                      </v-radio-group>

                      <v-radio-group style="margin-top: 0px" dense v-model  = "gasEscape.haveWarningLabelsBeenAffixedNoticeRaised" label = "If No, has a Warning notice been raised ?" row>
                          <v-radio label="Yes"  value="YES"></v-radio>
                          <v-radio label="No"   value="NO"></v-radio>
                      </v-radio-group>

     
                      </v-card-text>
                  </v-card> 
              </v-col>
               <!-- Warning Labels been affixed -->

              <!-- Faulty Component Returned -->
               <v-col cols="12" sm="6">
                      <v-card
                           elevation="2"
                           tile
                      >
                      <v-card-text>
                      <v-radio-group dense label = "Faulty Component returned ?" v-model  = "gasEscape.faultyComponentReturned">
                         <v-radio label="Yes"  value="YES"></v-radio>
                         <v-radio label="No"   value="NO"></v-radio>
                      </v-radio-group>
                      <v-text-field  dense outlined v-model = "gasEscape.faultyComponentReturnedComments" label="Comments"></v-text-field>
                      </v-card-text>
                      </v-card>
              </v-col>
              <!-- Faulty Component Returned -->

              <!--Additional Information -->
                <v-col cols="12" sm="6">
                      <v-card
                           elevation="2"
                           tile
                      >
                      <v-card-text >
                        <v-textarea clearable v-model  = "gasEscape.additionalInformation" dense outlined label = "Additional Information:"></v-textarea>
                    </v-card-text>
                  </v-card>
       
                </v-col>
              <!--Additional Information -->

              <!-- Fault -->
                <v-col cols="12">
                      <v-card
                           elevation="2"
                           tile
                      >
                      <v-card-text>
                        <v-textarea clearable v-model  = "gasEscape.fault" dense outlined label = "fault:"></v-textarea>
                    </v-card-text>
                  </v-card>
       
                </v-col>
              <!-- Fault -->
 
              </v-row>

        </v-container>
  
        </v-expansion-panel-content>
         </v-expansion-panel>
       <!-- end Section 3 - Escape Details-->

        <!-- last one for space-->
           <v-expansion-panel class="expansion_panel mb-2"> 
          </v-expansion-panel>
        <!-- last one for space-->


     </v-expansion-panels>
    </v-row>

  </div>

</template>

<script>

import {store} from '../../main.js'
import Localbase from 'localbase'


export default {
    name       : "JobSheet",
    formName   : "jobSheet",
    
    methods    : {


                saveDocument( customer, documentKey, documentObject ){
                      let db     = new Localbase( this.$dbName );
                      db.collection( customer ).doc(documentKey).set( documentObject )
                      .then(response => {
                        console.log( response)
                      })
                      .catch(error => {
                        console.log( error)
                      })
                },

                retrieveDocument( customer, documentKey ){

                    let db = new Localbase(  this.$dbName );
                    db.collection(customer).doc(documentKey).get()
                    .then( lastestObject => {
                      if ( lastestObject != null ) {

                        this.jobDetails      = lastestObject.formReviewData.jobDetails;
                        this.soundnessTest   = lastestObject.formReviewData.soundnessTest;
                        this.fitted          = lastestObject.formReviewData.fitted;
                        this.tankDetails     = lastestObject.formReviewData.tankDetails;
                        this.gasEscape       = lastestObject.formReviewData.gasEscape;
                      }
                    })
                    .catch( error => {
                       console.log( error)
                    });

                },

                  showSnackBar( message = "You need to review the form first..."){
                    this.MySnackBar.text          =  message;
                    this.MySnackBar.showImage     = "icon";
                    this.MySnackBar.show          = true;
                    setTimeout( () => { this.MySnackBar.show  = false }, 1000)
                  },
                  showUploadingDialog( message = "Uploading PDF...") {
                         this.uploadingDialog.message    = message;
                         this.uploadingDialog.showDialog = true;
                  },
                  closeUploadingDialog( message = "PDF Uploaded...") {
                          this.uploadingDialog.message    = message;
                          setTimeout( () => {
                                    this.uploadingDialog.message    = "";
                                    this.uploadingDialog.showDialog = false;
                                 }, 2000)
                  },
                  showAlert ( msg ) {
              this.alertDisplay      = true; 
              this.alertMsg          = msg; 
              setTimeout( () => { 
                this.alertDisplay      = false
              }, 3000);
                  },
                  generateSaveObject() {
                            let saveObject          =   { 
                                                          "formReviewName"      : "jobSheet",
                                                          "formReviewURL"       : this.$restServer.jobSheetURL,
                                                          "formReviewResponse"  : {},
                                                          "formReviewData"      : {  
                                                                                    "requestedResponse"   : "pdf",
                                                                                    "customer"            :  this.customer,
                                                                                    "salesman"            :  this.salesman,
                                                                                    "engineer"            :  this.engineer,
                                                                                    "jobDetails"          :  this.jobDetails,
                                                                                    "soundnessTest"       :  this.soundnessTest,
                                                                                    "fitted"              :  this.fitted,
                                                                                    "tankDetails"         :  this.tankDetails,
                                                                                    "gasEscape"           :  this.gasEscape,
                                                                                  } 
                                                        };
                            return saveObject;
                  }, 
                  generateLoggingObject() {
                     return  { 
                               "engineer"    : this.engineer['ENGINEER_ID'].toLowerCase(),
                               "account"     : this.customer['ACCOUNT'].toLowerCase(), 
                               "formName"    : this.formName 
                             };
                  },
                  formReviewUpload() {
                        const url                       =  this.$restServer.uploadPDFToDropBoxURL;
                        const formReviewReponse         =  store.retrieveFormReviewResponse();
                        if (  formReviewReponse  ) {

                            let   self        = this;
                            formReviewReponse['loggingObject']     = this.generateLoggingObject();
                            this.showUploadingDialog();
                            this.$http.post( url, formReviewReponse ) 
                            .then(  (response) => {
                                  store.resetFormReviewResponse();
                                  self.closeUploadingDialog();
                            })
                            .catch( (e) => { 
                                   self.closeUploadingDialog(e.message);
                                   localStorage.removeItem("equalResponsePdfReviewObject");
                                   console.log(e);
                            });
  
                        } else {
                              this.showSnackBar("You need to review the form first...");
                        }
                  },
                  formReview(e){
                
                        try {
                        
                            var saveObject  =  this.generateSaveObject();
                            store.saveFormObject("jobSheet", saveObject)
                            this.$router.push( { name:"FormReview", "params" : saveObject } );

                          } catch (e) {
                             alert(e);
                          }
                },
    },
    
    data() {
     return {

         MySnackBar        :  { "show" : false, "text" : "Default Text", "showImage" : "icon"},
         uploadingDialog   :  { "showDialog" : false, "message": "" },
         formName       : "JobSheet",
         customer       :  {},
         salesman       :  {},
         engineer       :  {} ,
         jobDetails     :  { 
                        "dateOfVisit"                       : "",
                        "arrivalTime"                       : "",
                        "departureTime"                     : "",
                        "engineer"                          : "",
                        "contractor"                        : "",
                        "detailOfWork"                      : "",
                        "typeOfInstallation"                : "",
                        "workCompleted"                     : false,
                        "workCompletedComment"              : "",
                        "internalInstallationInspected"     : false,
                        "internalInstallationSatisfactory"  : false,

                           },
         soundnessTest  :  [
                    {"label" : "High pressure",             "id": "highPressure" ,          "pressureTest"     : false,  "leakDetectionFluid" : false,  "installationSound": false},
                    {"label" : "Intermediate Pressure",     "id": "intermidiatePressure" ,  "pressureTest"     : false,  "leakDetectionFluid" : false,  "installationSound": false},
                    {"label" : "Low Pressure",              "id": "lowPressure" ,           "pressureTest"     : false,  "leakDetectionFluid" : false,  "installationSound": false},
                    {"label" : "internal Installation",     "id": "internalPressure" ,      "pressureTest"     : false,  "leakDetectionFluid" : false,  "installationSound": false},
                    {"label" : "liquid Installation",       "id": "liquidInstallation" ,    "pressureTest"     : false,  "leakDetectionFluid" : false,  "installationSound": false},
   
                           ],
         fitted         :  {
                            "ecv"                         : "NO" ,
                            "ecvCorrectlyLabelled"        : "" ,

                            "equipotentialBonding"        : "NO" ,
                            "equipotentialBondingAdvised" : "" ,
                            
                            "upso"                        : "NO" ,
                            "upsoDetails"                 : "" ,
         }, 
         tankDetails    :  {
                          "gasSupply"     : "",
                          "size"          : "",
                          "no"            : "",
                          "nextTestDate"  : "",
                          "nextTestDue"   : "",
                          "colour"        : "",
                          "gasContents"   : "",
                          "serialNumbers" : ""
                           },
         gasEscape      :  {
        
                          "escapeSmellLocation"                       : "",
                          "gasSupplyIsolated"                         : "",
                          "escapeDetail"                              : "",
                                      
                          "isGasSupplySafeForUse"                     : "",
                          "isInstallationSafeForUse"                  : "",
                          "isInstallationSafeForUseNoticeRaised"      : "",
                          "haveWarningLabelsBeenAffixed"              : "",
                          "haveWarningLabelsBeenAffixedNoticeRaised"  : "",
                          "isInstallationRiddorReportable"            : "",
                          "faultyComponentReturned"                   : "",
                          "fault"                                     : "",
                          "additionalInformation"                     : "",
                           },   
        };
    },

    destroyed () {

          store.consoleLog( "jobSheet destroyed");

          let saveObject           =  this.generateSaveObject();
          const customer           =  this.customer['ACCOUNT'];
          const documentKey        =  this.$options.formName;
          this.saveDocument( customer, documentKey, saveObject);
        
          /*  store.saveFormObject("jobSheet", jobSheetObject) */
    },

    created    () { 

        store.consoleLog( "jobSheet created");
        this.customer  = JSON.parse( localStorage.getItem("equalResponseCustomer") );
        this.salesman  = JSON.parse( localStorage.getItem("equalResponseSalesman") );
        this.engineer  = JSON.parse( localStorage.getItem("equalResponseEngineer") );

      
        //////////////////////////////////////////////
        //  reload the last JobSheet if any         //
        //////////////////////////////////////////////
        const customer           = this.customer['ACCOUNT'];
        const documentKey        = this.$options.formName;
        this.retrieveDocument( customer, documentKey);

        /*
        var tempObject = store.retrieveFormObject("jobSheet");
        if ( tempObject != null ) {
           this.jobDetails      = tempObject.formReviewData.jobDetails;
           this.soundnessTest   = tempObject.formReviewData.soundnessTest;
           this.fitted          = tempObject.formReviewData.fitted;
           this.tankDetails     = tempObject.formReviewData.tankDetails;
           this.gasEscape       = tempObject.formReviewData.gasEscape;
        }
        */
    },
  
};
</script>

<style scoped>

.myLabels >>> legend {
  font-weight: bold;
}

h1,h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.header{
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
}

.smallFont{
  font-family: 'Roboto';
  font-size: 14px;
}


input[type=text] {
  width: 100%;
  padding: 8px 20px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

div.v-text-field__details {
  display: none;
}


.parent {
    display: flex;
    flex-wrap: wrap;
}
 
 
  @media screen and (min-width:767px) {
      .child { flex: 1 0 50%; }
  }

 
#adjustme > div{
  padding: 0 !important;
 }

.uppercase{
  text-transform: uppercase;
}


</style>
           